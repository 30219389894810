import _ from 'lodash';
import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IRootStore } from '../store';

import { ApplicationVM } from './vm/application.vm';
import { CentralFeedVM } from './vm/central-feed.vm';
import { CommentsVM } from './vm/comments.vm';
import { EventsVM } from './vm/events.vm';
import { FeedVM } from './vm/feed.vm';
import { FilesVM } from './vm/files.vm';
import { GroupVM } from './vm/group.vm';
import { GroupsVM } from './vm/groups.vm';
import { MediaVM } from './vm/media.vm';
import { MembersVM } from './vm/members.vm';
import { TopicsVM } from './vm/topics.vm';
import { GroupRequestsVM } from './vm/group-requests.vm';
import { PricingPlansVM } from './vm/pricing-plans.vm';

import type { IViewModel } from './types';

export function initializeViewModel(
  router: UIRouter,
  store: IRootStore,
  params: ControllerParams,
): IViewModel {
  const comments = CommentsVM(params, store);

  return _.merge(
    comments,
    ApplicationVM(params, store),
    CentralFeedVM(params, comments, store),
    EventsVM(params, store),
    FeedVM(params, comments, store),
    FilesVM(params, store),
    GroupVM(params, store, router),
    GroupsVM(params, store),
    MediaVM(params, store),
    MembersVM(params, store),
    TopicsVM(params, store),
    GroupRequestsVM(params, store),
    PricingPlansVM(params, store),
  );
}

export function bindViewModel(
  vm: IViewModel,
  store: IRootStore,
  params: ControllerParams,
) {
  const { setProps } = params.flowAPI.controllerConfig;

  setProps({
    ..._.omit(vm, '_'),
    fitToContentHeight: true,
    store: store.getState(),
  });

  store.subscribe(() => setProps({ store: store.getState() }));
}

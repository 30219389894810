import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store, params) {
  return {
    name: 'group.events',
    url: '/events',
    data: {
      title: 'groups-web.events',
      application: GroupAppKey.EVENTS_APP,
    },
    resolve: [
      {
        token: 'eventsAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.EVENTS_APP,
          });
        },
      },
      {
        token: 'events',
        deps: ['groupId', 'eventsAvailable'],
        resolveFn(groupId: string, isEventsAvailable: boolean) {
          if (isEventsAvailable) {
            return vm.events$.fetch(groupId);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);

import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';

export default (function () {
  return [
    {
      name: 'group.custom',
      url: '/custom?',
      redirectTo: 'group.custom.tab',
    },
    {
      name: 'group.custom.tab',
      url: '/:tabId',
      data: {
        title: 'groups-web.custom',
        application: GroupAppKey.CUSTOM_APP,
      },
      params: {
        tabId: {
          type: 'string',
          value: 'custom_app',
          squash: true,
        },
      },
    },
  ];
} as StateDeclarationFn);

import type { RawParams } from '@wix/tpa-router';

import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';
import { selectCanApproveMembers, selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store) {
  return {
    name: 'group.members',
    url: '/members?nickname&role&expandJoinedRequests',
    data: {
      title: 'groups-web.members',
      application: GroupAppKey.MEMBERS_APP,
    },
    params: {
      order: {
        value: 'ASC',
        squash: true,
      },
      nickname: {
        type: 'URIEncoded',
        value: '',
        squash: true,
      },
      expandJoinedRequests: {
        type: 'bool',
        dynamic: true,
        value: false,
        squash: true,
      },
    },
    resolve: [
      {
        token: 'membersAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.MEMBERS_APP,
          });
        },
      },
      {
        token: 'members',
        deps: ['groupId', 'membersAvailable', '$stateParams'],
        resolveFn(
          groupId: string,
          isMembersAvailable: boolean,
          params: RawParams,
        ) {
          if (isMembersAvailable) {
            return vm.members$.query(groupId, params);
          }
        },
      },
      {
        token: 'joinRequests',
        deps: ['groupId', 'membersAvailable'],
        resolveFn(groupId: string, isMembersAvailable: boolean) {
          const canApprove = selectCanApproveMembers(store.getState(), groupId);

          if (isMembersAvailable && canApprove) {
            return vm.members$.fetchJoinRequests(groupId);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);

import type { RawParams } from '@wix/tpa-router';

import { EGroupPartition } from 'api/groups/types';

import type { StateDeclarationFn } from 'controller/types';
import { LayoutType } from 'settings/consts';

import settingsParams from '../settingsParams';

export default (function (vm, store, params) {
  const { settings } = params.flowAPI;
  const { isMobile } = params.flowAPI.environment;

  return {
    name: 'groups.list',
    url: `?partition&{title:URIEncoded}&sort`,
    params: {
      partition: {
        squash: true,
        value: EGroupPartition.ALL,
      },
      sort: {
        squash: true,
        value: settings.get(settingsParams.sortBy),
      },
    },
    resolve: [
      {
        token: 'groups',
        deps: ['$stateParams', 'layout'],
        async resolveFn(params: RawParams, layout: LayoutType) {
          switch (layout) {
            case LayoutType.list:
              return vm.groups$.query({
                ...params,
                limit: 6,
                namespace: params.partition,
                partition: params.partition,
              });

            case LayoutType.grid:
              return vm.groups$.query({
                ...params,
                limit: 6,
                namespace: params.partition,
                partition: params.partition,
              });

            case LayoutType.sideBar:
            default:
              return isMobile
                ? Promise.resolve([])
                : Promise.all([
                    vm.groups$.query({
                      namespace: EGroupPartition.JOINED,
                      partition: EGroupPartition.JOINED,
                      limit: 5,
                    }),
                    vm.groups$.query({
                      namespace: EGroupPartition.SUGGESTED,
                      partition: EGroupPartition.SUGGESTED,
                      limit: 10,
                    }),
                  ]);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
